import React from 'react';

const ContactSidebar = () => {
    return (
        <div className="sidebar-widget-wrapper">
            <div className="support-contact mb-30">
                <div className="support-tittle">
                    <h4>Contactos de Soporte</h4>
                </div>
                <div className="support-contact-inner">
                    <div className="support-item">
                        <div className="support-icon">
                            <svg id="Layer_6" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" width="21.375"
                                height="21.375" viewBox="0 0 21.375 21.375">
                                <path id="Path_8" data-name="Path 8"
                                    d="M1.688,16.386c.038-.031,4.3-3.085,5.463-2.885.556.1.875.477,1.513,1.238.1.123.35.415.541.624a8.877,8.877,0,0,0,1.176-.479,9.761,9.761,0,0,0,4.5-4.5A8.876,8.876,0,0,0,15.363,9.2c-.209-.192-.5-.439-.628-.544-.756-.634-1.135-.953-1.233-1.51C13.3,6,16.354,1.725,16.386,1.687A1.631,1.631,0,0,1,17.6,1c1.238,0,4.774,4.586,4.774,5.359,0,.045-.065,4.608-5.691,10.331C10.966,22.31,6.4,22.375,6.359,22.375,5.586,22.375,1,18.84,1,17.6A1.629,1.629,0,0,1,1.688,16.386Zm4.75,4.56c.623-.051,4.452-.556,9.239-5.26,4.727-4.813,5.22-8.653,5.269-9.248a19.276,19.276,0,0,0-3.353-3.962c-.028.029-.066.071-.115.127a25.216,25.216,0,0,0-2.546,4.32,8.469,8.469,0,0,0,.724.649,7.149,7.149,0,0,1,1.077,1.013l.173.242-.051.293A8.135,8.135,0,0,1,16.166,11,11.193,11.193,0,0,1,11,16.166a8.115,8.115,0,0,1-1.882.688l-.293.051-.242-.173A7.209,7.209,0,0,1,7.568,15.65c-.223-.266-.522-.622-.634-.722A25.054,25.054,0,0,0,2.6,17.477c-.059.05-.1.088-.128.113a19.259,19.259,0,0,0,3.962,3.354Z"
                                    transform="translate(-1 -1)" fill="#2467ec" />
                            </svg>
                        </div>
                        <div className="support-info-phone">
                            <span>Teléfono</span>
                            <p>Colombia :<a href="tel:(+57)3122898647"> (+57) 312 289 8647</a></p>
                        </div>
                    </div>
                    <div className="support-item">
                        <div className="support-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.57" height="16.271"
                                viewBox="0 0 22.57 16.271">
                                <g id="email_3_" data-name="email (3)" transform="translate(-1.25 -4.25)">
                                    <path id="Path_10" data-name="Path 10"
                                        d="M20.933,20.521H4.137A2.9,2.9,0,0,1,1.25,17.634V7.137A2.9,2.9,0,0,1,4.137,4.25h16.8A2.9,2.9,0,0,1,23.82,7.137v10.5A2.9,2.9,0,0,1,20.933,20.521Zm-16.8-14.7A1.312,1.312,0,0,0,2.825,7.137v10.5a1.312,1.312,0,0,0,1.312,1.312h16.8a1.312,1.312,0,0,0,1.312-1.312V7.137a1.312,1.312,0,0,0-1.312-1.312Z"
                                        transform="translate(0 0)" fill="#2467ec" />
                                    <path id="Path_11" data-name="Path 11"
                                        d="M12.534,13.7a3.412,3.412,0,0,1-1.732-.472L1.638,7.778a.8.8,0,0,1-.283-1.05A.777.777,0,0,1,2.4,6.455l9.175,5.438a1.774,1.774,0,0,0,1.848,0L22.6,6.455a.777.777,0,0,1,1.05.273.8.8,0,0,1-.283,1.05l-9.1,5.448a3.412,3.412,0,0,1-1.732.472Z"
                                        transform="translate(0.001 0.105)" fill="#2467ec" />
                                </g>
                            </svg>

                        </div>
                        <div className="support-info-email">
                            <span>Correo</span>
                            <a href="mailto:soporte@cloudsoft.site">soporte@cloudsoft.site</a>
                        </div>
                    </div>
                    <div className="support-item">
                       
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSidebar;