import React,{useState} from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {BASE_URL} from '../../../config'
import ReCAPTCHA from "react-google-recaptcha";
const ContactFormSection = () => {

    

    const {userData} = useSelector(state=> state)
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const [sended, setSended] = useState(false);


    const validationSchema = Yup.object().shape({
        nombres: Yup.string().required('Nombres requeridos'),
        apellidos: Yup.string().required('Apellidos requeridos'),
        correo: Yup.string().email('Correo inválido').required('Correo requerido'),
        mensaje: Yup.string().required('Mensaje requerido'),
      });

      const handleSendMail = async (values,setSubmitting) => {
        try {
            const payload = {
                nombres: values.nombres + ' ' +values.apellidos,
                correo: values.correo,
                mensaje: values.mensaje
            }
            const {data} = await axios.post(BASE_URL+'/mail',payload)
            setSubmitting(false)
            setSended(true)
        } catch (error) {
            console.log(error)
            setSubmitting(false)
        }
      }

      return (
        <div className="contact-form">
            <Formik
                initialValues={{ nombres: '', apellidos: '', correo: '', mensaje: '' }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleSendMail(values,setSubmitting)
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="contact-from-input mb-20">
                                    <Field type="text" name="nombres" placeholder="Nombres" />
                                    <ErrorMessage name="nombres" component="div" />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="contact-from-input mb-20">
                                    <Field type="text" name="apellidos" placeholder="Apellidos" />
                                    <ErrorMessage name="apellidos" component="div" />
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="contact-from-input mb-20">
                                    <Field type="email" name="correo" placeholder="Correo" />
                                    <ErrorMessage name="correo" component="div" />
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div className="contact-from-input mb-20">
                                    <Field as="textarea" name="mensaje" placeholder="Mensaje" />
                                    <ErrorMessage name="mensaje" component="div" />
                                </div>
                            </div>
                            <div className="col-xl-2 mb-20">
                            <ReCAPTCHA
                            sitekey="6LcwPEcpAAAAADM6mWiUbUF0y-mh0SmrRX-RMH0a"
                            onChange={(token) => setRecaptchaToken(token)}
                            />
                            </div>
                        </div>

                        <div className='row'>
                        {recaptchaToken != "" &&
                            <div className="col-lg-4 mt-5">
                                <div className="cont-btn mb-20">
                                    <button type="submit" className="cont-btn" disabled={isSubmitting}>
                                        {isSubmitting ? 'Enviando...' : 'Enviar Mensaje'}
                                    </button>
                                </div>
                            </div>
                        }
                        {sended &&
                            <div className="col-lg-12">
                                <div className="alert alert-success">Mensaje Enviado</div>                                
                            </div>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ContactFormSection;