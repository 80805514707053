import * as React from "react"
import Layout from "../components/layout"
import ContactMain from "../components/contact"
import { Seo } from "../components/seo"
const ContactPage = () => (
  <Layout>
    <Seo
      title="Apprende Online | Contacto"
      description="Usa el formulario para pedir soporte o ponerse en contacto"
    />
    <ContactMain />
  </Layout>
)

export default ContactPage